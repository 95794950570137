import { create } from "zustand";
import { system_api, tickets_api } from "@/api/requests";
import { ICurrentTicket, ITicket } from "@/interfaces/tickets";

interface ITicketStore {
  test: string;
  tickets: ITicket[] | [];
  setTickets: (tickets: ITicket[]) => void;
  filterSearch: string | null;
  handleClearSearch: number;
  setHandleClearSearch: (handleClearSearch: number) => void;
  setFilterSearch: (filter: string | null) => void;
  searchTickets: ITicket[] | [];
  filter: string | null;
  comments: any;
  ticketCreateModal: {
    isOpen: boolean;
    ticket: ICurrentTicket | null;
    setTicketCreateModal: (isOpen: boolean) => void;
    getTypes: () => Promise<any>;
    getCategories: () => Promise<any>;
    getImportance: () => Promise<any>;
    createNewTicket: (ticket: any) => Promise<any>;
  };

  isLoading: boolean;
  setFilter: (filter: string | null) => void;

  ticketsValidation: any;

  getCurrentTicket: (id: number) => Promise<any>;

  getTickets: (filter?: number) => Promise<{
    status: number;
    data: any;
  }>;

  getTicketsOnApprove: () => Promise<{
    status: number;
    data: any;
  }>;
  getTicketsOnComplete: () => Promise<{
    status: number;
    data: any;
  }>;
  getTicketsOnReject: () => Promise<{
    status: number;
    data: any;
  }>;

  getTicketComments: (id: number) => Promise<any>;
  createNewComment: (id: number, comment: string) => Promise<any>;
  deleteTicketComment: (id: number, commentId: number) => Promise<any>;
  updateTicketComment: (
    id: number,
    commentId: number,
    comment: string
  ) => Promise<any>;
  updateTicketScore: (id: number, body: any) => Promise<any>;
}

export const ticketStore = create<ITicketStore>((set) => ({
  test: "test",
  tickets: [],
  handleClearSearch: 0,
  setHandleClearSearch: (handleClearSearch) => {
    set((state) => ({
      ...state,
      handleClearSearch,
    }))
  },
  searchTickets: [],
  filterSearch: null,
  comments: null,
  setFilterSearch: (filter) => set({ filterSearch: filter }),
  setTickets: (tickets) => set({ searchTickets: tickets }),
  filter: "Активные",
  isLoading: false,
  ticketsValidation: null,
  setFilter: (filter) => set({ filter }),
  ticketCreateModal: {
    isOpen: false,
    ticket: null,
    setTicketCreateModal: (isOpen) => {
      set((state) => ({
        ...state,
        ticketCreateModal: {
          ...state.ticketCreateModal,
          isOpen,
        },
      }));
    },
    getTypes: async () => {
      const result = await system_api.getTypesForCreateTicket();
      // console.log(result, 'types from bx24 data')
      return result;
    },
    getCategories: async () => {
      const result = await system_api.getCategoriesForCreateTicket();
      // console.log(result, 'categories from bx24 data')
      return result;
    },
    getImportance: async () => {
      const result = await system_api.getImportanceForCreateTicket();
      // console.log(result, 'importance from bx24 data')
      return result;
    },
    createNewTicket: async (ticket) => {
      const result = await tickets_api.handleCreateTicket(ticket);
      return result;
    },
  },

  getCurrentTicket: async (id) => {
    const response = await tickets_api.getTicketsByID(id);

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    return data;
  },

  getTickets: async (filter) => {
    set({ isLoading: true });
    const response = await tickets_api.getTickets();

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    const inWorkTickets: ITicket[] = [];

    const waitForApproveTickets: ITicket[] = [];

    const otherTickets: ITicket[] = [];

    data.data
      .sort((a: ITicket, b: ITicket) => b.rate - a.rate)
      .map((ticket: ITicket) => {
        if (ticket.status === 6) {
          inWorkTickets.push(ticket);
        } else if (ticket.status === 7) {
          waitForApproveTickets.push(ticket);
        } else {
          otherTickets.push(ticket);
        }
      });

    const result = [
      ...waitForApproveTickets,
      ...inWorkTickets,
      ...otherTickets,
    ];

    if (filter) {
      console.log('here if')
      set({
        tickets: result.filter((ticket) => ticket.status === filter),
        ticketsValidation: data.validation,
        isLoading: false,
      });
    } else {
                  console.log('here else')

      set({
        tickets: result,
        ticketsValidation: data.validation,
        isLoading: false,
      });
    }

    return data;
  },

  getTicketsOnApprove: async () => {
    set({ isLoading: true });
    const response = await tickets_api.getTicketsNotConfirm();

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    const result = [
      ...data.data.sort((a: ITicket, b: ITicket) => b.rate - a.rate),
    ];

    set({
      tickets: result,
      // ticketsValidation: data.validation,
      isLoading: false,
    });

    return data;
  },
  getTicketsOnComplete: async () => {
    set({ isLoading: true });
    const response = await tickets_api.getTicketsCompletion();

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    const result = [
      ...data.data.sort((a: ITicket, b: ITicket) => b.rate - a.rate),
    ];

    set({
      tickets: result,
      // ticketsValidation: data.validation,
      isLoading: false,
    });

    return data;
  },
  getTicketsOnReject: async () => {
    set({ isLoading: true });
    const response = await tickets_api.getTicketsRejected();

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    const result = [
      ...data.data.sort((a: ITicket, b: ITicket) => b.rate - a.rate),
    ];

    set({
      tickets: result,
      // ticketsValidation: data.validation,
      isLoading: false,
    });

    return data;
  },
  getTicketComments: async (id) => {
    const response = await tickets_api.getTicketComments(id);

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    return data;
  },
  createNewComment: async (id, comment) => {
    const response = await tickets_api.createNewComment(id, comment);

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    return data;
  },
  deleteTicketComment: async (id, commentId) => {
    const response = await tickets_api.deleteTicketComment(id, commentId);

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    return data;
  },
  updateTicketComment: async (id, commentId, ticket) => {
    const response = await tickets_api.updateTicketComment(
      id,
      commentId,
      ticket
    );

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    return data;
  },
  updateTicketScore: async (id, body) => {
    const response = await tickets_api.updateTicketScore(id, body);

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }
    if (status !== 200) {
      throw new Error(data);
    }

    return data;
  },
}));
