import { ICurrentTicket } from "@/interfaces/tickets";
import { Box, Skeleton, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import ImageModal from "./ImageModal";

import ImageViewer from "react-simple-image-viewer";

import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

interface IProps {
  currentTicket: ICurrentTicket | null;
}

const TicketBodyDetails = ({ currentTicket }: IProps) => {
  const [open, setOpen] = useState(false);

  const zoomRef = useRef(null);

  return (
    <>
      {currentTicket?.importance ? (
        <Typography variant="body1" sx={{ mb: 2 }}>
          Важность: {currentTicket?.importance}
        </Typography>
      ) : null}
      {currentTicket?.brand ? (
        <Typography variant="body1" sx={{ mb: 2 }}>
          Платформа: {currentTicket?.brand}
        </Typography>
      ) : null}
      {currentTicket?.category ? (
        <Typography variant="body1" sx={{ mb: 2 }}>
          Категория: {currentTicket?.category}
        </Typography>
      ) : null}
      {currentTicket?.typeFiled ? (
        <Typography variant="body1" sx={{ mb: 2 }}>
          Тип: {currentTicket?.typeFiled}
        </Typography>
      ) : null}
      {currentTicket?.whoApproves ? (
        <Typography variant="body1" sx={{ mb: 2 }}>
          Кто апрувит: {currentTicket?.whoApproves}
        </Typography>
      ) : null}

      {/* {currentTicket?.images ? (
        <>
          <ImageModal
            images={currentTicket.images}
            isOpen={isImageViewerOpen}
            handleClose={() => setIsImageViewerOpen(false)}
          />
          <Typography variant="body1" sx={{ mb: 2 }}>
            Скриншоты: <br />
            {currentTicket?.images.map((image) => (
              <img
                key={image.urlMachine}
                src={image.urlMachine}
                onClick={() => setIsImageViewerOpen(true)}
                style={{ width: "300px" }}
              />
            ))}
          </Typography>
        </>
      ) : (
        <Skeleton variant="text" width="400px" height={30} />
      )} */}
      {currentTicket?.images ? (
        <Box>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Скриншоты: <br />
          </Typography>
          {currentTicket?.images.map((src, index) => (
            <img
              src={src.urlMachine}
              // onClick={() => openImageViewer(index)}
              onClick={() => setOpen(true)}
              width="300"
              key={index}
              style={{ margin: "2px" }}
              alt=""
            />
          ))}

          <Lightbox
            open={open}
            close={() => setOpen(false)}
            plugins={[Zoom]}
            zoom={{
              scrollToZoom: true,
            }}
            slides={currentTicket?.images.map((image) => {
              return {
                src: image.urlMachine,
                title: image.urlMachine,
              };
            })}
          />

          {/* {isViewerOpen && (
            <ImageViewer
              src={currentTicket?.images.map((image) => image.urlMachine)}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
          )} */}
        </Box>
      ) : (
        <Skeleton variant="text" width="400px" height={30} />
      )}
    </>
  );
};

export default TicketBodyDetails;
