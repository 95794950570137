import { IconButton, Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { animation } from "./styles";
import { useNavigate } from "react-router-dom";

const AnimatedHelpIcon = () => {
  const navigate = useNavigate();

  const handleManual = () => {
    navigate("/manual");
  };

  return (
    <Tooltip title="Помощь">
      <IconButton
        size="small"
        color="primary"
        sx={animation}
        onClick={handleManual}
      >
        <HelpIcon />
      </IconButton>
    </Tooltip>
  );
};

export default AnimatedHelpIcon;
