import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
} from "@mui/material";
import {
  SearchContainer,
  SearchWrapper,
  deviderStyles,
  inputStyles,
} from "./styles";
import { ticketStore } from "@/store/ticketStore";
import toast from "react-hot-toast";
import useDebounce from "@/hooks/useDebounce";
import { useEffect, useState } from "react";

const Search = () => {
  const { tickets, setTickets, setFilterSearch, handleClearSearch } =
    ticketStore();

  const [search, setSearch] = useState<string>("");

  const debouncedValue = useDebounce<string>(search, 300);

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    setFilterSearch(e.target.value);
  };

  useEffect(() => {
    const searchResult = tickets.filter((ticket) => {
      return (
        ticket.title.toLowerCase().includes(search.toLowerCase()) ||
        ticket.id.toString().includes(search)
      );
    });

    if (searchResult.length === 0 && search.length > 0) {
      toast.error("Ничего не найдено");
      setTickets([]);
    } else {
      setTickets(searchResult);
    }

    // if (search.length === 0) {
    //   setFilterSearch("");
    // }
  }, [debouncedValue]);

  useEffect(() => {
    setSearch("");
  }, [handleClearSearch]);

  return (
    <Grid item xs={12}>
      <Box sx={SearchContainer}>
        <Paper component="form" sx={SearchWrapper}>
          <InputBase
            sx={inputStyles}
            placeholder="Поиск"
            onChange={handleSearch}
            value={search}
          />

          <Divider sx={deviderStyles} orientation="vertical" />

          <IconButton sx={{ p: "10px" }} color="info">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>
    </Grid>
  );
};

export default Search;
