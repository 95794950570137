import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

const Loading = () => {
  return (
    <Box sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }}>
      <CircularProgress disableShrink />
      <Typography variant="h6">Загрузка...</Typography>
    </Box>
  );
};

export default Loading;
