import { createTheme } from "@mui/material/styles";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#0084E3",
    },
    secondary: {
      main: "#000",
    },
    error: {
      main: "#ff0000",
      dark: "rgba(255,0,0,0.3)",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          transition: "all 0.3s ease-in-out",
        },
      },
    },
  },
});
