import { createRoot } from "react-dom/client";
import App from "@/App";
import GlobalWrapper from "@/components/Wrappers/GlobalWrapper";
import "./index.css";

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <GlobalWrapper>
    <App />
  </GlobalWrapper>
);
