import { ticketStore } from "@/store/ticketStore";
import { Box, Button, Typography } from "@mui/material";
import React from "react";

const NotFound = () => {
  const { setFilterSearch, filterSearch, setHandleClearSearch } = ticketStore();

  const handleClear = () => {
    setFilterSearch("");
    setHandleClearSearch(Math.random());
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="50"
        height="50"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.728 1H6.272L1 6.272V13.728L6.272 19H13.728L19 13.728V6.272L13.728 1ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z"
          fill="#F11616"
        />
      </svg>

      <Typography variant="h6" component="h4" align="center">
        По запросу "{filterSearch}" ничего не найдено
      </Typography>
      {filterSearch && (
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          color="primary"
          onClick={handleClear}
        >
          Очистить поиск
        </Button>
      )}
    </Box>
  );
};

export default NotFound;
