import { ticketStore } from "@/store/ticketStore";
import { Modal, Box, Typography, Button } from "@mui/material";
import React from "react";
import toast from "react-hot-toast";

interface IProps {
  ticket: {
    open: boolean;
    commentId: number;
    ticketId: number;
  };
  handleClose: () => void;
  getCurrentTicketComments: (ticketId: number) => void;
}

const TicketCommentDeleteModal = ({
  ticket,
  handleClose,
  getCurrentTicketComments,
}: IProps) => {
  const { deleteTicketComment } = ticketStore();

  const deleteComment = () => {
    deleteTicketComment(ticket.ticketId, ticket.commentId)
      .then((res: any) => {
        getCurrentTicketComments(ticket.ticketId)
        handleClose();
        toast.success('Комментарий успешно удален');
      })
      .catch((e) => {
        console.error(e)
        toast.error('Ошибка при удалении комментария');
      });
  };
  return (
    <Modal open={ticket.open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Удалить комментарий?
        </Typography>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          fullWidth
          onClick={handleClose}
        >
          Отмена
        </Button>
        <Button
          variant="contained"
          sx={{ mt: 1 }}
          fullWidth
          color="error"
          onClick={deleteComment}
        >
          Удалить
        </Button>
      </Box>
    </Modal>
  );
};

export default TicketCommentDeleteModal;
