import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { IChildrenProps } from "@/interfaces/global";
import { lightTheme } from "@/themes/light";

const GlobalWrapper = ({ children }: IChildrenProps) => {
  return (
    <BrowserRouter
      basename={
        process.env.REACT_APP_MODE === "prod" ? "/api/bitrix/form" : "/"
      }
    >
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default GlobalWrapper;
