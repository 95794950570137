export const categoriesBtns = {
  mr: {
    xs: "5px",
    md: 1,
  },
  minWidth: {
    xs: "150px",
    md: "170px",
    wordWrap: "nowrap",
  },
  height: {
    xs: "30px",
    md: "40px",
  },
  borderRadius: "25px",
  fontSize: {
    xs: "12px",
    md: "14px",
  },
};

export const categoryContainer = {
  p: 2,
  display: "flex",
  alignItems: "center",
  justifyContent: {
    xs: "flex-start",
    lg: "center",
  },
  flexWrap: "nowrap",
  boxShadow: "0px 15px 25px 1px rgba(0, 0, 0, 0.1)",
  overflowX: {
    xs: "scroll",
    md: "auto",
  },
};
