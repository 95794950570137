import { create } from "zustand";
import { auth_api } from "@/api/requests";
import { IProfile } from "@/interfaces/profile";

interface IAuthStore {
  profile: null | IProfile;
  permissions: null | string[];

  getProfile: () => Promise<{
    status: number;
    data: IProfile;
  }>;
  getPermissions: () => Promise<{
    status: number;
    data: {
      data: string[];
      statusCode: number;
    };
  }>;
  getToken: () => Promise<any>
}

export const authStore = create<IAuthStore>((set) => ({
  profile: null,
  permissions: null,

  getProfile: async () => {
    const response = await auth_api.getProfile();

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set({ profile: data.data });

    return data;
  },
  getPermissions: async () => {
    const response = await auth_api.getPermissions();

    const { status, data } = response;

    if (!status || !data) {
      throw new Error("No status code or data returned from server.");
    }

    if (status !== 200) {
      throw new Error(data);
    }

    set({ permissions: data.data });

    return data;
  },
  getToken: async () => {
    const response = await auth_api.getToken();

    console.log(response, 'response from get token')

    return response;
  }
}));
