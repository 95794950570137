import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import Tikets from "@/components/pages/Tikets/Tikets";
import Manual from "@/components/pages/Manual/Manual";

import { authStore } from "@/store/authStore";
import { ticketStore } from "./store/ticketStore";

import toast, { Toaster } from "react-hot-toast";

const App = () => {
  const { getProfile, profile, getPermissions, getToken } = authStore();
  const { getTickets } = ticketStore();

  const [countRequests, setCountRequests] = useState<number>(0);

  const reloadHandler = () => {
    setCountRequests((prev) => prev + 1);
    if (countRequests < 20) {
      getProfile().catch((e) => {
        console.log(e);
        // toast.error("Ошибка при получении профиля");
        alert("Ошибка при получении токена, попробуйте перезагрузить страницу")
        getToken().catch((e) => {
          console.log(e);
          toast.error("Ошибка при получении токена");
        });
        reloadHandler();
      });

      getTickets().catch((e) => {
        console.log(e);
        // toast.error("Ошибка при получении тикетов");
        alert("Ошибка при получении токена, попробуйте перезагрузить страницу")
        getToken().catch((e) => {
          console.log(e);
          toast.error("Ошибка при получении токена");
        });
        reloadHandler();
      });

      getPermissions().catch((e) => {
        console.log(e);
        // toast.error("Ошибка при получении прав");
        alert("Ошибка при получении токена, попробуйте перезагрузить страницу")
        getToken().catch((e) => {
          console.log(e);
          toast.error("Ошибка при получении токена");
        });
        reloadHandler();
      });
    } else {
      toast.error(
        "Ошибка при получении данных, попробуйте перезагрузить страницу"
      );
    }

    setCountRequests(0);
  };

  useEffect(() => {
    console.log(process.env.REACT_APP_MODE, "MODE");
    if (process.env.REACT_APP_MODE === "dev") {
      reloadHandler();
    } else {
      const windowCustom = window as any;

      if (windowCustom?.BX24) {
        const { BX24 } = windowCustom;

        BX24.init(function () {
          reloadHandler();
        });
      }
    }
  }, []);
  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#fff",
            color: "#000",
          },

          // Default options for specific types
          error: {
            duration: 3000,
            style: {
              background: "#F11616",
              color: "#fff",
            },
            icon: "⚠️",
          },

          success: {
            duration: 3000,
            style: {
              background: "#41B619",
              color: "#fff",
            },
            icon: "✅",
          },
        }}
      />
      <Routes>
        <Route path="/" element={<Tikets />} />
        <Route path="/manual" element={<Manual />} />
      </Routes>
    </>
  );
};

export default App;
