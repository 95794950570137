import { ArrowRight, Home, Info, Lightbulb } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";

const linkStyle = {
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  color: "black",
};

const manualPages = ["Создание тикетов", "Поиск тикетов", "Фильтра", "Тикеты"];

const Manual = () => {
  const [currentPage, setCurrentPage] = useState("");

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          p: 1,
        }}
      >
        <Breadcrumbs>
          <Link to="/" style={linkStyle}>
            <Home sx={{ mr: 0.5 }} fontSize="inherit" />
            Домой
          </Link>
          <Link
            to="/manual"
            style={linkStyle}
            onClick={() => setCurrentPage("")}
          >
            <Lightbulb sx={{ mr: 0.5 }} fontSize="inherit" />
            Мануал
          </Link>
          {currentPage && (
            <Typography color="primary">{currentPage}</Typography>
          )}
        </Breadcrumbs>
        <Divider sx={{ my: 1 }} />
      </Grid>
      {currentPage === "" && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {manualPages.map((page) => (
                <Button
                  onClick={() => setCurrentPage(page)}
                  startIcon={<ArrowRight />}
                >
                  {page}
                </Button>
              ))}
            </Box>
          </Grid>
        </Grid>
      )}
      {currentPage === "Создание тикетов" && (
        <Grid
          container
          spacing={1}
          sx={{
            px: 1,
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h4">Создание тикетов</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Для создания тикет нужно нажать кнопку "НОВЫЙ ТИКЕТ" чтобы открыть
              форму создания тикета.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img
              alt={"img"}
              src={"/static/Create-ticket-1.gif"}
              style={{
                width: "100%",
                maxWidth: "500px",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Описание полей формы:</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>-Тема тикета:</strong> Обязательное поле для указания
              названия тикета. Она должна коротко описывать проблему.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>-Тип:</strong> Обязательное поле у которого есть два типа
              (Репорт бага, Запрос Фичи). Если нужно добавить новий функционал
              то выбираем "Запрос Фичи", если не корректно отрабатывает
              существующий то "Репорт бага".
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>-Скриншоты:</strong> Обязательное поле в которое можно
              добавить несколько изображений. В идеале это должен быть скриншот
              всего экрана на котором выделена зона с проблемой.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>-Бренд URL:</strong> Это поле обязательно если тип тикета
              "Репорт бага", в этом поле нужно вписать ссылку на платформы с
              проблемой или указать там что проблема на всех платформах.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>-Категория:</strong> Это поле обязательно если тип тикета
              "Репорт бага", выберите к какой категрии относится баг.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>-Важность:</strong> Обязательное поле в котором нужно
              выбрать один из пунктов.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>-Оценка постановщика:</strong> В этом пункте нужно указать
              насколько вы оцениваете этот тикет, от 0 до 10. От этой оценки
              зависит приоритет задач у разработчика. Чем больше цифра тем
              быстрее данная задача попадет в работу.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              После заполнения всех необходимых полей для создания тикета
              нажмите "Отправить"
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <img
              alt={"img"}
              src={"/static/Create-ticket-2.png"}
              style={{
                width: "100%",
                maxWidth: "500px",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              После отправки тикет попадает на обработку в категорию фильтрации
              "На проверке", как только тикет пройдёт верификацию он появиться в
              списке тикетов "В очереди"
            </Typography>
          </Grid>
        </Grid>
      )}

      {currentPage === "Поиск тикетов" && (
        <Grid
          container
          spacing={1}
          sx={{
            px: 1,
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h4">Поиск тикетов</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Для поиска тикетов нужно ввести часть названия тикета
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img
              alt={"img"}
              src={"/static/Search-Ticket-1.gif"}
              style={{
                width: "100%",
                maxWidth: "500px",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Также можно ввести ID тикета для поиска
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img
              alt={"img"}
              src={"/static/Search-Ticket-2.gif"}
              style={{
                width: "100%",
                maxWidth: "500px",
              }}
            />
          </Grid>
        </Grid>
      )}

      {currentPage === "Фильтра" && (
        <Grid
          container
          spacing={1}
          sx={{
            px: 1,
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h4">Фильтрация тикетов</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Для фильтрации представлены несколько категорий которые можно
              выбрать в верхнем меню приложения.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img
              alt={"img"}
              src={"/static/Filtration-Tikects-1.gif"}
              style={{
                width: "100%",
                maxWidth: "500px",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Описание Фильтров:</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>-Активные:</strong> Отображает тикеты которые "В работе",
              "В очереди" и "Ожидает апрува"
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>-В работе:</strong> Отображает тикеты которые в процессе
              исполнение.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>-В очереди:</strong> Отображает тикеты в очереди на взятие
              в работу, тикеты беруться в работу по самой большой оценке.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>-На проверке:</strong> Это тикеты которые еще не прошли
              проверку после создания и должны обрабатываться чтобы перейти в
              категрию "В очереди".
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>-Ожидает апрува:</strong>Отображает задачи которые
              выполнены програмистом и ожидает апрува сапортом или постановщиком
              задачи.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>-Завершен:</strong> Отображает тикеты которые уже
              завершены.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>-Отклоненные:</strong> Отображает тикеты которые не прошли
              утверждение и не будут брать в работу.
            </Typography>
          </Grid>
        </Grid>
      )}

      {currentPage === "Тикеты" && (
        <Grid
          container
          spacing={1}
          sx={{
            px: 1,
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h4">Описание Тикетов</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              На скриншоте выше изображен список тикетов.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img
              alt={"img"}
              src={"/static/Tickets-1.png"}
              style={{
                width: "100%",
                maxWidth: "500px",
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              Тикеты задача по которым завершены командой разработки и еще
              находятся на стадии утверждения закрашены зеленым цветом и всегда
              отображаются в начале списка "Активне".
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Тикеты закрашенные фиолетовым цветом, отображаются всегда сверху
              списка но ниже тикетов закрашенных зеленым цветом, также это
              означает что эти тикеты в работе.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">
              Также на изображении выше указаны пункты:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>1.</strong>Заголовок тикета
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img
              alt={"img"}
              src={"/static/Tickets-2.png"}
              style={{
                width: "100%",
                maxWidth: "500px",
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>2.</strong>Сумма оценок от всех стейкхолдеров,
              постановщика и разработчиков. Если кто-то не участвовал в
              голосовании от его имени автоматически проставляется минимальная
              оценка в 1 бал.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img
              alt={"img"}
              src={"/static/Tickets-3.png"}
              style={{
                width: "100%",
                maxWidth: "500px",
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>3.</strong> Значок бага
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img
              alt={"img"}
              src={"/static/Tickets-5.png"}
              style={{
                width: "100%",
                maxWidth: "500px",
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>4.</strong> Значок фичи
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img
              alt={"img"}
              src={"/static/Tickets-4.png"}
              style={{
                width: "100%",
                maxWidth: "500px",
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Manual;
