import { ICurrentTicket, ITicket } from "@/interfaces/tickets";
import { ticketStore } from "@/store/ticketStore";
import { ExpandMore } from "@mui/icons-material";
import { Box, Grid, Paper, Button } from "@mui/material";
import { useState } from "react";
import TicketComments from "./TicketComments";

import TicketHeader from "./TicketHeader";
import { ticketHeaderWrapper, ticketItemContainer } from "./styles";
import TicketBody from "./TicketBody";
import TicketBodyDetails from "./TicketBodyDetails";
import TicketScores from "./TicketScores";

const TicketItem = ({ ticket }: { ticket: ITicket }) => {
  const { getCurrentTicket } = ticketStore();
  const [expanded, setExpanded] = useState<boolean>(false);

  const [expandedAdditionInfo, setExpandedAdditionInfo] =
    useState<boolean>(false);
  const [currentTicket, setCurrentTicket] = useState<ICurrentTicket | null>(
    null
  );

  const handleGetCurrentTicket = (id: any) => {
    getCurrentTicket(ticket.id).then((res) => {
      setCurrentTicket(res?.data);
    });
  };

  const handleOpenTicket = (ticket: ITicket) => {
    handleGetCurrentTicket(ticket.id);
    setExpanded(!expanded);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper
          sx={{
            ...ticketItemContainer,
            backgroundColor:
              ticket.status === 6 && !expanded
                ? "#3D3BC3"
                : ticket.status === 7 && !expanded
                ? "#bbed21"
                : null,
          }}
        >
          <Box
            sx={ticketHeaderWrapper}
            onClick={() => handleOpenTicket(ticket)}
          >
            <TicketHeader ticket={ticket} expanded={expanded} />
          </Box>
          {expanded && (
            <>
              <Grid item xs={12}>
                <TicketBody currentTicket={currentTicket} />

                <Button
                  color="primary"
                  onClick={() => setExpandedAdditionInfo(!expandedAdditionInfo)}
                >
                  Дополнительное описание
                  <ExpandMore
                    sx={{
                      ml: 1,
                      transition: "0.3s",
                      transform: expandedAdditionInfo
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    }}
                  />
                </Button>

                {expandedAdditionInfo && (
                  <TicketBodyDetails currentTicket={currentTicket} />
                )}

                <TicketScores
                  currentTicket={currentTicket}
                  handleGetCurrentTicket={handleGetCurrentTicket}
                />
              </Grid>
              <Grid item xs={12}>
                <TicketComments ticket={ticket} />
              </Grid>
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TicketItem;
