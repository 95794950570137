import ScoreSlider from "@/components/UI/ScoreSlider/ScoreSlider";
import { ICurrentTicket } from "@/interfaces/tickets";
import { authStore } from "@/store/authStore";
import { ticketStore } from "@/store/ticketStore";
import {
  Paper,
  InputLabel,
  Grid,
  Slider,
  Box,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";

interface IProps {
  currentTicket: ICurrentTicket | null;

  handleGetCurrentTicket: (id: any) => void;
}

const TicketScores = ({ currentTicket, handleGetCurrentTicket }: IProps) => {
  const { ticketsValidation, updateTicketScore, getCurrentTicket } =
    ticketStore();
  const { permissions } = authStore();

  const [ticketValidationArr, setTicketValidationArr] = useState<any>([]);
  const [ultrascore, setUltrascore] = useState<number | null>(null);

  const handleRejectScore = () => {
    console.log("reject");
    if (currentTicket) {
      setUltrascore(+currentTicket["Edit ultra stakeholder score"]);
    }
  };

  const handleApproveOtherScores = (value: any) => {
    console.log("approve");
    if (currentTicket?.id) {
      updateTicketScore(currentTicket?.id, value).then(() => {
        handleGetCurrentTicket(currentTicket?.id);
      });
    }
  };

  const handleApproveScore = () => {
    console.log("approve");
    if (currentTicket?.id) {
      updateTicketScore(currentTicket?.id, {
        "Edit ultra stakeholder score": ultrascore,
      }).then(() => {
        handleGetCurrentTicket(currentTicket?.id);
      });
    }
  };

  useEffect(() => {
    console.log(ticketsValidation, "ticketsValidation");
    console.log(permissions, "permissions");

    const result = Object.keys(ticketsValidation).filter((key) => {
      if (key !== "ratingAuthor") {
        return key;
      }
    });

    setTicketValidationArr(result);
  }, []);

  return (
    <Grid
      item
      xs={12}
      sx={{
        // opacity: 0.3,
      }}
    >
      {/* <Typography variant="h6">В розработке</Typography> */}
      {permissions && ticketValidationArr.length > 0
        ? ticketValidationArr.map((validationKey: string) => {
            return permissions?.includes(validationKey) &&
              validationKey !== "Edit ultra stakeholder score" ? (
              <Paper
                key={validationKey}
                sx={{
                  backgroundColor: "rgba(239, 239, 240,1)",
                  px: 1,
                  py: 1,
                  my: 1,
                }}
                elevation={5}
              >
                <InputLabel>Оценка {validationKey}</InputLabel>
                <ScoreSlider
                  handleApproveOtherScores={handleApproveOtherScores}
                  validationKey={validationKey}
                  currentTicket={currentTicket}
                />
              </Paper>
            ) : (
              permissions?.includes("Edit ultra stakeholder score") && (
                <Box key={validationKey}>
                  <Paper
                    sx={{
                      backgroundColor: "rgba(239, 239, 240,1)",
                      px: 1,
                      py: 1,
                      my: 1,
                    }}
                    elevation={5}
                  >
                    <InputLabel>Оценка {validationKey}</InputLabel>
                    <Box sx={{ width: "100%" }}>
                      <TextField
                        type="number"
                        inputProps={{
                          min: -9999,
                          max: 9999,
                          step: 1,
                        }}
                        variant="standard"
                        value={
                          ultrascore !== null
                            ? ultrascore
                            : currentTicket
                            ? +currentTicket["Edit ultra stakeholder score"]
                            : 1
                        }
                        onChange={(e: any) => setUltrascore(+e.target.value)}
                      />
                      <Slider
                        step={1}
                        defaultValue={0}
                        value={
                          ultrascore
                            ? ultrascore
                            : currentTicket
                            ? +currentTicket["Edit ultra stakeholder score"]
                            : 1
                        }
                        color="secondary"
                        onChange={(e: any) => setUltrascore(e.target.value)}
                        min={
                          +ticketsValidation["Edit ultra stakeholder score"]
                            .MIN_VALUE
                        }
                        max={
                          +ticketsValidation["Edit ultra stakeholder score"]
                            .MAX_VALUE
                        }
                        valueLabelDisplay="auto"
                      />
                    </Box>
                  </Paper>
                  {currentTicket !== null &&
                    ultrascore !== null &&
                    ultrascore !==
                      +currentTicket["Edit ultra stakeholder score"] && (
                      <Box
                        sx={{
                          backgroundColor: "rgba(0,0,255,0.2)",
                          borderRadius: "5px",
                          p: "10px",
                          mt: "10px",
                        }}
                      >
                        <Typography
                          variant="h6"
                          component="h2"
                          sx={{
                            textAlign: "right",
                          }}
                        >
                          Поставить оценку: {ultrascore}
                        </Typography>
                        <Box
                          sx={{
                            mt: "10px",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ mr: "10px" }}
                            size="small"
                            onClick={handleApproveScore}
                          >
                            Поставить оценку
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={handleRejectScore}
                          >
                            Отменить оценку
                          </Button>
                        </Box>
                      </Box>
                    )}
                </Box>
              )
            );
          })
        : null}
    </Grid>
  );
};

export default TicketScores;
