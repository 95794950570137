import PageWrapper from "@/components/Wrappers/PageWrapper";
import Search from "@/components/pages/Tikets/components/Search/Search";
import ActionNavigation from "@/components/pages/Tikets/components/ActionNavigation/ActionNavigation";
import Filter from "@/components/pages/Tikets/components/Filter/Filter";
import TicketItem from "@/components/pages/Tikets/components/TicketItem/TicketItem";
import { Grid } from "@mui/material";
import { ticketStore } from "@/store/ticketStore";
import Loading from "@/components/UI/Loading/Loading";
import NotFound from "@/components/UI/NotFound/NotFound";

const Tikets = () => {
  const { tickets, isLoading, searchTickets, filterSearch } = ticketStore();
  return (
    <PageWrapper>
      <Search />
      <ActionNavigation />
      <Filter />
      <Grid item xs={12}>
        {!isLoading ? (
          searchTickets?.length || filterSearch ? (
            searchTickets?.length ? searchTickets.map((ticket) => (
              <TicketItem key={ticket.id} ticket={ticket} />
            )) : <NotFound />
          ) : (
            tickets?.length ? tickets.map((ticket) => (
              <TicketItem key={ticket.id} ticket={ticket} />
            )) : <NotFound />
          )
        ) : (
          <Loading />
        )}
      </Grid>
    </PageWrapper>
  );
};

export default Tikets;
