export const SearchWrapper = {
  p: "2px 4px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: 800,
  width: "100%",
  mx: 1,
  boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.1)",
  borderRadius: '30px',
};

export const SearchContainer = {
  display: "flex",
  justifyContent: "center",
  py: 1,
};

export const inputStyles = { ml: 1, flex: 1 };

export const deviderStyles = { height: 28, m: 0.5 };

export const btnStyles = { my: "5px" }
