export const animation = {
  "@keyframes pulse": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(1.1)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  animation: "pulse 1.5s infinite",
  mx: 2,
  color: "#0084E3",
};
