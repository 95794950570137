import { Box, Button, Typography } from "@mui/material";
import { Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useRef, useState } from "react";
import { ticketStore } from "@/store/ticketStore";
import { ICurrentTicket } from "@/interfaces/tickets";

interface IProps {
  validationKey: string;
  currentTicket: ICurrentTicket | null;
  handleApproveOtherScores: (value: any) => void;
}

const ScoreSlider = ({ validationKey, currentTicket, handleApproveOtherScores }: IProps) => {
  const { ticketsValidation } = ticketStore();

  const [value, setValue] = useState<number>(1);
  const [arrFromTo, setArrFromTo] = useState<any>([]);

  const refSwiper = useRef<any>(null);

  const handleApprove = (key: string, value: number) => {
    handleApproveOtherScores({
      [key]: value,
    });
  }

  const onClickSwiper = (e: any) => {
    if (refSwiper !== null) {
      refSwiper?.current?.swiper?.slideTo(e.clickedIndex);
    }
  };

  const handleChangeScore = (e: any) => {
    setValue(e.activeIndex + 1);
  };

  const getScoreNumber = (score: any, array: any) => {
    return array.indexOf(score);
  };

  const handleRejectScore = (field: string) => {
    refSwiper?.current?.swiper?.slideTo(currentTicket?.[field] - 1);
    setValue(currentTicket?.[field]);
  };

  useEffect(() => {
    if (ticketsValidation[validationKey]?.DEFAULT_VALUE) {
      const generatedArray = [];

      for (
        let i = ticketsValidation[validationKey]?.MIN_VALUE;
        i <= ticketsValidation[validationKey]?.MAX_VALUE;
        i++
      ) {
        generatedArray.push(i);
      }

      setArrFromTo(generatedArray);
    }
  }, []);

  useEffect(() => {
    if (currentTicket) {
      console.log(
        currentTicket?.[validationKey],
        "currentTicket?.[validationKey]"
      );
      refSwiper?.current?.swiper?.slideTo(currentTicket?.[validationKey] - 1);
    }
  }, [currentTicket]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Swiper
          modules={[Virtual]}
          ref={refSwiper}
          style={{
            marginLeft: 0,
            marginRight: 0,
            width: "100%",
            minWidth: 300,
            maxWidth: "400px",
          }}
          spaceBetween={1}
          slidesPerView={10}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          onAfterInit={(swiper) => {
            swiper.slideTo(
              getScoreNumber(
                ticketsValidation[validationKey]?.DEFAULT_VALUE,
                arrFromTo
              )
            );
          }}
          // controller={{ control: score }}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 0,
            modifier: 7,
            slideShadows: true,
          }}
          onActiveIndexChange={handleChangeScore}
          onClick={onClickSwiper}
          onSliderFirstMove={() => console.log("onSliderFirstMove")}
          pagination={true}
          // modules={[EffectCoverflow]}
        >
          {arrFromTo?.map((i: number) => (
            <SwiperSlide
              key={i}
              style={{
                width: "auto",
                height: "auto",
                maxWidth: "40px",
                maxHeight: "40px",
              }}
            >
              {({ isActive }) => (
                <Typography
                  variant="h4"
                  component="h2"
                  sx={{
                    cursor: "pointer",
                    borderRadius: "5px",
                    py: "6px",
                    textAlign: "center",
                    fontSize: "18px",
                    color: isActive ? "white" : "gray",
                    backgroundColor: isActive ? "primary.main" : "transparent",
                  }}
                >
                  {i}
                </Typography>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Box>
        {currentTicket !== null && value !== currentTicket[validationKey] && (
          <Box
            sx={{
              backgroundColor: "rgba(0,0,255,0.2)",
              borderRadius: "5px",
              p: "10px",
              mt: "10px",
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              sx={{
                textAlign: "right",
              }}
            >
              Поставить оценку: {value}
            </Typography>
            <Box
              sx={{
                mt: "10px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ mr: "10px" }}
                size="small"
                onClick={() => handleApprove(validationKey, value)}
              >
                Поставить оценку
              </Button>
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={() => handleRejectScore(validationKey)}
              >
                Отменить оценку
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ScoreSlider;
