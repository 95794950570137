import { ICurrentTicket } from "@/interfaces/tickets";
import { Typography, Skeleton, Divider } from "@mui/material";

interface IProps {
    currentTicket: ICurrentTicket | null;
}

const TicketBody = ({currentTicket}: IProps) => {
  return (
    <>
      {currentTicket?.id ? (
        <Typography variant="body1" sx={{ mb: 2 }}>
          ID: {currentTicket?.id}
        </Typography>
      ) : (
        <Skeleton variant="text" width="100px" height={30} />
      )}

      {currentTicket?.tasks ? (
        <Typography variant="body1" sx={{ mb: 2 }}>
          Задачи:{" "}
          {currentTicket?.tasks.map((task) => (
            <a
              style={{
                textDecoration: +task?.status === 5 ? "line-through" : "none",
              }}
              key={task.id}
              href={`https://erpfk.com/company/personal/user/159/tasks/task/view/${task.id}/`}
              target="_blank"
            >
              {task.id}
            </a>
          ))}
        </Typography>
      ) : (
        <Skeleton variant="text" width="200px" height={30} />
      )}

      {currentTicket?.description ? (
        <Typography
          variant="body1"
          sx={{
            mb: 2,
            whiteSpace: "pre-wrap",
            width: "100%",
            maxWidth: "100%",
          }}
        >
          Описание: <br />
          {currentTicket?.description}
        </Typography>
      ) : (
        <>
          <Skeleton variant="text" width="100%" height={30} />
          <Skeleton variant="text" width="100%" height={30} />
          <Skeleton variant="text" width="100%" height={30} />
        </>
      )}

      {currentTicket?.createdBy ? (
        <Typography variant="body1" sx={{ mb: 2 }}>
          Кем созданно:{" "}
          {`${currentTicket?.createdBy?.NAME} ${currentTicket?.createdBy?.LAST_NAME}`}
        </Typography>
      ) : (
        <Skeleton variant="text" width="400px" height={30} />
      )}

      <Divider sx={{ my: 1 }} />

      
    </>
  );
};

export default TicketBody;
