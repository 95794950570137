import { IChildrenProps } from "@/interfaces/global";
import { Grid } from "@mui/material";
import React from "react";

const PageWrapper = ({ children }: IChildrenProps) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        minHeight: "100vh",
        flexDirection: "column",
        justifyContent: "flex-start"
      }}
    >
      {children}
    </Grid>
  );
};

export default PageWrapper;
