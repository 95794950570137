import { ITicketType } from "@/interfaces/tickets";
import { ticketStore } from "@/store/ticketStore";
import { Close } from "@mui/icons-material";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Input,
  Button,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import toast from "react-hot-toast";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/pagination";
import "swiper/css/virtual";

const TicketCreateModal = () => {
  const refSwiper = useRef<any>(null);
  const [value, setValue] = useState<any>(0);
  const { ticketCreateModal, ticketsValidation } = ticketStore();
  const {
    isOpen,
    setTicketCreateModal,
    getTypes,
    getCategories,
    getImportance,
    createNewTicket,
  } = ticketCreateModal;

  const [formErrors, setFormErrors] = useState<any>({});

  const [typesValues, setTypesValues] = useState<ITicketType[]>([]);
  const [categoriesValues, setCategoriesValues] = useState<ITicketType[]>([]);
  const [importanceValues, setImportanceValues] = useState<ITicketType[]>([]);
  const [arrFromTo, setArrFromTo] = useState<any>([]);

  const [newTicket, setNewTicket] = useState<any>({});

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTicket({
      ...newTicket,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setNewTicket({
      ...newTicket,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTicket({
      ...newTicket,
      [e.target.name]: e.target.files,
    });
  };

  const handleClose = () => {
    setTicketCreateModal(false);
    setNewTicket({});
    setValue(0);
    setImportanceValues([]);
    setCategoriesValues([]);
    setTypesValues([]);
    setFormErrors({});
  };

  function readFileAsync(file: any) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const handleSubmitTicket = async () => {
    const validation: any = {};
    console.log(!newTicket?.ticketTitle, newTicket?.ticketTitle, formErrors);
    if (!newTicket?.ticketTitle) {
      validation.ticketTitle = "*Должно быть заполнено";
    }

    if (!newTicket?.description) {
      validation.description = "*Должно быть заполнено";
    }

    if (newTicket?.ticketTitle?.length < 6) {
      validation.ticketTitle = "Минимум 6 символов";
    }
    if (newTicket?.ticketTitle?.length > 50) {
      validation.ticketTitle = "Максимум 50 символов";
    }

    if (newTicket?.description?.length < 6) {
      validation.description = "Минимум 6 символов";
    }

    if (newTicket?.description?.length > 500) {
      validation.description = "Максимум 500 символов";
    }

    if (!newTicket?.brandUrl) {
      validation.brandUrl = "*Должно быть заполнено";
    }

    if (!newTicket?.type) {
      validation.type = "*Должно быть заполнено";
    }

    if (!newTicket?.category) {
      validation.category = "*Должно быть заполнено";
    }

    if (!newTicket?.importance) {
      validation.importance = "*Должно быть заполнено";
    }

    if (newTicket?.type?.toString() !== "4818") {
      if (!newTicket?.screenshots) {
        validation.screenshots = "*Должно быть заполнено";
      }
    }

    if (Object.keys(validation).length) {
      setFormErrors(validation);
      toast.error("Заполните все поля");
      return;
    } else {
      setFormErrors({});
    }

    let dataFiles = [];
    if (newTicket?.screenshots?.length) {
      for (const file of newTicket.screenshots) {
        let result: any = await readFileAsync(file);
        dataFiles.push([file.name, result.split(",")[1]]);
      }
    }

    const ticket = {
      ufCrm29_1671562646: newTicket.description,
      ufCrm29_1671562844: newTicket.brandUrl,
      ufCrm29_1671562788: dataFiles,
      ufCrm29_1671563584997: newTicket.importance,
      ufCrm29_1671563694253: newTicket.category,
      ufCrm29_1678112543: newTicket.type,
      ufCrm29_1678111345:
        value || ticketsValidation?.ratingAuthor?.DEFAULT_VALUE || 1,
      title: newTicket.ticketTitle,
    };

    createNewTicket(ticket)
      .then((res) => {
        console.log(res, "res");
        handleClose();
        toast.success("Тикет успешно создан");
      })
      .catch((err) => console.log(err));

    console.log(ticket, "newTicket ");
  };

  const handleChangeScore = (e: any) => {
    setValue(e.activeIndex + 1);
  };
  const onClickSwiper = (e: any) => {
    if (refSwiper !== null) {
      refSwiper?.current?.swiper?.slideTo(e.clickedIndex);
    }
  };
  const getScoreNumber = (score: any, array: any) => {
    return array.indexOf(score);
  };

  useEffect(() => {
    // console.log('v1')
    getTypes()
      .then((res) => {
        setTypesValues(res);
        // console.log(res, "res");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Ошибка при получении данных types");
      });

    getCategories()
      .then((res) => {
        setCategoriesValues(res);
        // console.log(res, "res");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Ошибка при получении данных categories");
      });

    getImportance()
      .then((res) => {
        setImportanceValues(res);
        // console.log(res, "res");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Ошибка при получении данных importance");
      });

    if (process.env.REACT_APP_MODE === "dev") {
      setArrFromTo([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
    } else {
      if (ticketsValidation?.ratingAuthor?.DEFAULT_VALUE) {
        const generatedArray = [];

        for (
          let i = ticketsValidation?.ratingAuthor?.MIN_VALUE;
          i <= ticketsValidation?.ratingAuthor?.MAX_VALUE;
          i++
        ) {
          generatedArray.push(i);
        }

        setArrFromTo(generatedArray);
      }
    }
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        mx: 1,
      }}
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: 800,
          width: "100%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          maxHeight: "100vh",
          overflowY: "scroll",
          borderRadius: "7px",
          border: "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            sx={{
              fontSize: {
                xs: "20px",
                md: "30px",
              },
            }}
          >
            Создать Ticket
          </Typography>
          <IconButton onClick={handleClose} color="error">
            <Close />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Тема тикета"
              name="ticketTitle"
              size="small"
              onChange={handleInputChange}
              fullWidth
              value={newTicket?.ticketTitle || ""}
              variant="outlined"
              sx={{
                mt: 1,
              }}
            />
            {formErrors?.ticketTitle && (
              <Typography color="error">{formErrors?.ticketTitle}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {typesValues?.length && (
              <FormControl fullWidth size="small">
                <InputLabel>Тип</InputLabel>
                <Select
                  value={newTicket?.type || ""}
                  label="Тип"
                  name="type"
                  onChange={handleChangeSelect}
                >
                  {typesValues.map((type: ITicketType) => {
                    return (
                      <MenuItem key={type.ID} value={type.ID}>
                        {type.VALUE}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            {formErrors?.type && (
              <Typography color="error">{formErrors?.type}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Описание"
              fullWidth
              size="small"
              multiline
              rows={4}
              name="description"
              onChange={handleInputChange}
              variant="outlined"
              value={newTicket?.description || ""}
            />
            {formErrors?.description && (
              <Typography color="error">{formErrors?.description}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Скриншоты</InputLabel>
            <TextField
              type="file"
              size="small"
              inputProps={{ multiple: true }}
              fullWidth
              name="screenshots"
              onChange={handleInputFileChange}
              variant="outlined"
              sx={{ mt: 1 }}
            />
            {formErrors?.screenshots && (
              <Typography color="error">{formErrors?.screenshots}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Бренд url"
              fullWidth
              name="brandUrl"
              size="small"
              onChange={handleInputChange}
              variant="outlined"
              value={newTicket?.brandUrl || ""}
            />
            {formErrors?.brandUrl && (
              <Typography color="error">{formErrors?.brandUrl}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {categoriesValues?.length && (
              <FormControl fullWidth size="small">
                <InputLabel>Категория</InputLabel>
                <Select
                  value={newTicket?.category || ""}
                  label="Категория"
                  name="category"
                  onChange={handleChangeSelect}
                >
                  {categoriesValues.map((category: ITicketType) => {
                    return (
                      <MenuItem key={category.ID} value={category.ID}>
                        {category.VALUE}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            {formErrors?.category && (
              <Typography color="error">{formErrors?.category}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {importanceValues?.length && (
              <FormControl fullWidth size="small">
                <InputLabel>Важность</InputLabel>
                <Select
                  value={newTicket?.importance || ""}
                  label="Важность"
                  name="importance"
                  onChange={handleChangeSelect}
                >
                  {importanceValues.map((importance: ITicketType) => {
                    return (
                      <MenuItem key={importance.ID} value={importance.ID} sx={{
                        fontSize: {
                          xs: "14px",
                          md: "16px",
                        },
                        wordBreak: "break-all",
                        whiteSpace: "break-spaces",
                      }}>
                        {importance.VALUE}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            {formErrors?.importance && (
              <Typography color="error">{formErrors?.importance}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Оценка постановщика</InputLabel>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Swiper
                modules={[Virtual]}
                ref={refSwiper}
                style={{
                  marginLeft: 0,
                  marginRight: 0,
                  width: "100%",
                  minWidth: 300,
                  maxWidth: "400px",
                }}
                spaceBetween={1}
                slidesPerView={10}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                onAfterInit={(swiper) => {
                  swiper.slideTo(
                    getScoreNumber(
                      ticketsValidation?.ratingAuthor?.DEFAULT_VALUE | 1,
                      arrFromTo
                    )
                  );
                }}
                // controller={{ control: score }}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 0,
                  modifier: 7,
                  slideShadows: true,
                }}
                onActiveIndexChange={handleChangeScore}
                onClick={onClickSwiper}
                onSliderFirstMove={() => console.log("onSliderFirstMove")}
                pagination={true}
                // modules={[EffectCoverflow]}
              >
                {arrFromTo?.map((i: number) => (
                  <SwiperSlide
                    key={i}
                    style={{
                      width: "auto",
                      height: "auto",
                      maxWidth: "40px",
                      maxHeight: "40px",
                    }}
                  >
                    {({ isActive }) => (
                      <Typography
                        variant="h4"
                        component="h2"
                        sx={{
                          cursor: "pointer",
                          borderRadius: "5px",
                          py: "6px",
                          textAlign: "center",
                          fontSize: "18px",
                          color: isActive ? "white" : "gray",
                          backgroundColor: isActive
                            ? "primary.main"
                            : "transparent",
                        }}
                      >
                        {i}
                      </Typography>
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>

            {formErrors?.importance && (
              <Typography color="error">{formErrors?.importance}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth onClick={handleSubmitTicket}>
              Отправить
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default TicketCreateModal;
