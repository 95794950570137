import AnimatedHelpIcon from "@/components/UI/AnimatedHelpIcon/AnimatedHelpIcon";
import { ticketStore } from "@/store/ticketStore";
import { Add, RotateLeft } from "@mui/icons-material";
import { Button, Grid, IconButton, Box } from "@mui/material";
import React from "react";
import TicketCreateModal from "../TicketCreateModal/TicketCreateModal";
import { authStore } from "@/store/authStore";
import { toast } from "react-hot-toast";

const ActionNavigation = () => {
  const { ticketCreateModal, getTickets, setFilter } = ticketStore();
  const { getToken, getProfile, getPermissions } = authStore();
  const { setTicketCreateModal, isOpen } = ticketCreateModal;

  const handleOpen = () => {
    setTicketCreateModal(true);
  };

  const handleReload = () => {
    setFilter("Активные");
    getToken().catch((err) => {
      console.log(err);
    });
    getProfile().catch((e) => {
      console.log(e);
      toast.error("Ошибка при получении профиля");
      getToken().catch((e) => {
        console.log(e);
        toast.error("Ошибка при получении токена");
      });
    });

    getTickets().catch((e) => {
      console.log(e);
      toast.error("Ошибка при получении тикетов");
      getToken().catch((e) => {
        console.log(e);
        toast.error("Ошибка при получении токена");
      });
    });

    getPermissions().catch((e) => {
      console.log(e);
      toast.error("Ошибка при получении прав");
      getToken().catch((e) => {
        console.log(e);
        toast.error("Ошибка при получении токена");
      });
    });
  };
  return (
    <Grid item xs={12}>
      {isOpen && <TicketCreateModal />}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <AnimatedHelpIcon />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="text"
            color="primary"
            sx={{ ml: 2, color: "#0084E3" }}
            endIcon={<Add />}
            onClick={handleOpen}
          >
            НОВЫЙ ТИКЕТ
          </Button>
          <IconButton
            size="small"
            sx={{ mr: 2, color: "#0084E3" }}
            onClick={handleReload}
          >
            <RotateLeft />
          </IconButton>
        </Box>
      </Box>
    </Grid>
  );
};

export default ActionNavigation;
