export const ticketItemContainer = {
  my: "5px",
  mx: 1,
  px: {
    xs: "2px",
    md: 1,
  },
  transition: "all 0.5s ease",
  border: "1px solid black",
};

export const ticketHeaderWrapper = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  cursor: "pointer",
};

export const ticketHeaderContainer = {
  display: "flex",
  alignItems: "center",
  width: "100%",
  minWidth: "220px",
};

export const ticketHeaderTitleExpanded = {
  width: "100%",
  fontSize: {
    xs: "16px",
    sm: "18px",
    md: "20px",
  },
};

export const ticketHeaderTitle = {
  ...ticketHeaderTitleExpanded,
  wordWrap: "noWrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
};
