import { ticketStore } from "@/store/ticketStore";
import { Button, Grid, Paper } from "@mui/material";
import toast from "react-hot-toast";
import { categoriesBtns, categoryContainer } from "./styles";
import { ITicket } from "@/interfaces/tickets";

const titles = [
  { title: "Активные", sort: 0 },
  { title: "В работе", sort: 6 },
  { title: "В очереди", sort: 5 },

  { title: "На проверке", sort: 2 },
  { title: "Завершен", sort: 3 },
  { title: "Отклоненные", sort: 4 },

  { title: "Ожидает апрува", sort: 7 },
];

const Filter = () => {
  const {
    filter,
    setFilter,
    getTickets,
    getTicketsOnApprove,
    getTicketsOnComplete,
    getTicketsOnReject,
    setTickets,
    tickets,
    filterSearch,
  } = ticketStore();

  const handleWithFilterSearch = (tickets: ITicket[]) => {
    if (tickets.length > 0 && filterSearch) {
      const filteredTickets = tickets.filter((ticket) => {
        return (
          ticket.title.toLowerCase().includes(filterSearch.toLowerCase()) ||
          ticket.id.toString().includes(filterSearch)
        );
      });
      setTickets(filteredTickets);
    } else {
      setTickets([]);
    }
  };

  const handleFilter = (item: { title: string; sort: number }) => {
    setFilter(item.title);

    if ([0, 5, 7, 6].includes(item.sort)) {
      getTickets(item.sort)
        .then((res) => {
          if (item.sort !== 0) {
            console.log('here')
            handleWithFilterSearch(
              res.data.filter((i: ITicket) => i.status === item.sort)
            );
          } else {
            handleWithFilterSearch(res.data);
          }
        })
        .catch((e) => {
          console.error(e);
          toast.error("Ошибка при получении тикетов");
        });
    }

    if (item.sort === 2) {
      getTicketsOnApprove()
        .then((res) => {
          handleWithFilterSearch(res.data);
        })
        .catch((e) => {
          console.error(e);
          toast.error("Ошибка при получении тикетов");
        });
    }

    if (item.sort === 3) {
      getTicketsOnComplete()
        .then((res) => {
          handleWithFilterSearch(res.data);
        })
        .catch((e) => {
          console.error(e);
          toast.error("Ошибка при получении тикетов");
        });
    }

    if (item.sort === 4) {
      getTicketsOnReject()
        .then((res) => {
          handleWithFilterSearch(res.data);
        })
        .catch((e) => {
          console.error(e);
          toast.error("Ошибка при получении тикетов");
        });
    }
  };
  return (
    <Grid item xs={12}>
      <Paper sx={categoryContainer}>
        {titles.map((i) => (
          <Button
            key={i.sort}
            sx={{
              ...categoriesBtns,
              color: filter === i.title ? "white" : "black",
              borderColor: filter === i.title ? "white" : "black",
            }}
            onClick={() => handleFilter(i)}
            variant={filter === i.title ? "contained" : "outlined"}
          >
            {i.title}
          </Button>
        ))}
      </Paper>
    </Grid>
  );
};

export default Filter;
