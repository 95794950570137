import { ITicket } from "@/interfaces/tickets";
import { ticketStore } from "@/store/ticketStore";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

// @ts-ignore
import BBCode from '@bbob/react';
// @ts-ignore
import presetReact from '@bbob/preset-react';

import { authStore } from "@/store/authStore";
import { Delete, Edit } from "@mui/icons-material";
import TicketCommentDeleteModal from "./TicketCommentDeleteModal";
import toast from "react-hot-toast";
import { parseBitrixBbCode } from "@/helpers/parseBitrixString";
import ts from "typescript";

const TicketComments = ({ ticket }: { ticket: ITicket }) => {
  const { profile } = authStore();
  const { getTicketComments, createNewComment, updateTicketComment } =
    ticketStore();
  const [comments, setComments] = useState([]);

  const [newComment, setNewComment] = useState("");
  const [deleteCommentModal, setDeleteCommentModal] = useState({
    open: false,
    commentId: 0,
    ticketId: 0,
  });

  const [editCommentModal, setEditCommentModal] = useState({
    open: false,
    commentId: 0,
    ticketId: 0,
    comment: "",
  });

  const handleNewComment = (e: any) => {
    setNewComment(e.target.value);
  };

  const deleteComment = (ticketId: number, commentId: number) => {
    setDeleteCommentModal({
      open: true,
      commentId,
      ticketId,
    });
  };

  const editComment = (
    ticketId: number,
    commentId: number,
    comment: string
  ) => {
    setEditCommentModal({
      open: true,
      commentId,
      ticketId,
      comment: comment,
    });
  };

  const handleEditCommentChange = (e: any) => {
    setEditCommentModal({
      ...editCommentModal,
      comment: e.target.value,
    });
  };

  const handleDeclineEditComment = () => {
    setEditCommentModal({
      open: false,
      commentId: 0,
      ticketId: 0,
      comment: "",
    });
  };

  const handleEditComment = () => {
    updateTicketComment(
      editCommentModal.ticketId,
      editCommentModal.commentId,
      editCommentModal.comment
    )
      .then((res: any) => {
        setEditCommentModal({
          open: false,
          commentId: 0,
          ticketId: 0,
          comment: "",
        });
        getCurrentTicketComments(ticket.id);
        toast.success("Комментарий успешно изменен");
      })
      .catch((e) => {
        console.error(e);
        toast.error("Ошибка при изменении комментария");
      });
  };

  const handleCloseTicketModal = () => {
    setDeleteCommentModal({
      open: false,
      commentId: 0,
      ticketId: 0,
    });
  };

  const handleCreateNewComment = () => {
    createNewComment(ticket.id, newComment)
      .then((res: any) => {
        setNewComment("");
        getCurrentTicketComments(ticket.id);
        toast.success("Комментарий успешно создан");
      })
      .catch((e) => {
        toast.error("Ошибка при создании комментария");
        console.error(e);
      });
  };

  const getCurrentTicketComments = (id: number) => {
    getTicketComments(id)
      .then((res: any) => {
        setComments(res.data);
        // console.log(res.data);
      })
      .catch((e) => {
        toast.error("Ошибка при получении комментария");
        console.error(e);
      });
  };

  useEffect(() => {
    getCurrentTicketComments(ticket.id);
  }, []);

  return (
    <Grid
      item
      xs={12}
      sx={{
        p: 1,
      }}
    >
      <TicketCommentDeleteModal
        ticket={deleteCommentModal}
        handleClose={handleCloseTicketModal}
        getCurrentTicketComments={getCurrentTicketComments}
      />

      <Typography variant="body1" sx={{ backgroundColor: "#F4F4F4", p: 1 }}>
        Комментарии: {comments?.length}
      </Typography>
      <Divider sx={{ mb: 1 }} />
      {comments?.map((comment: any) => (
        <Grid
          key={comment.id}
          sx={{
            backgroundColor: "#F4F4F4",
            mb: 1,
            p: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "5px",
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              {comment.author}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {dayjs(comment.createdAt).format("DD/MM/YYYY")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {comment.id === editCommentModal.commentId ? (
              <TextField
                multiline
                value={editCommentModal.comment}
                fullWidth
                onChange={handleEditCommentChange}
              >
                {editCommentModal.comment}
              </TextField>
            ) : (
              <Typography
                variant="body2"
                sx={{
                  mb: 2,
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                  width: "100%",
                  maxWidth: "100%",
                }}
              >
                {/* {comment.comment} */}
                <BBCode plugins={[presetReact()]}>
                  {comment.comment}
                </BBCode>

                {/* {parseBitrixBbCode(comment.comment)} */}

                {/* {parse(
                  parseBitrixString(he.decode(parser.toHTML(comment.comment)))
                )} */}
              </Typography>
            )}
          </Box>
          {profile?.ID === comment.authorId &&
            comment.id !== editCommentModal.commentId && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  color="error"
                  sx={{
                    fontSize: "12px",
                  }}
                  onClick={() => deleteComment(ticket.id, comment.id)}
                >
                  <Delete sx={{ fontSize: "15px" }} />
                  Удалить
                </Button>
                <Button
                  color="primary"
                  sx={{
                    ml: 1,
                    fontSize: "12px",
                  }}
                  onClick={() =>
                    editComment(ticket.id, comment.id, comment.comment)
                  }
                >
                  <Edit sx={{ fontSize: "15px" }} />
                  Отредактировать
                </Button>
              </Box>
            )}
          {comment.id === editCommentModal.commentId && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                mt: 1,
              }}
            >
              <Button
                color="error"
                sx={{
                  fontSize: "12px",
                }}
                onClick={handleDeclineEditComment}
              >
                Отменить
              </Button>
              <Button
                color="primary"
                sx={{
                  ml: 1,
                  fontSize: "12px",
                }}
                onClick={handleEditComment}
              >
                Сохранить
              </Button>
            </Box>
          )}
        </Grid>
      ))}
      <Grid item xs={12}>
        <TextField
          label="Ваш комментарий"
          multiline
          rows={4}
          fullWidth
          value={newComment}
          onChange={handleNewComment}
          placeholder="Ваш комментарий"
          variant="outlined"
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          mt: 1,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateNewComment}
          sx={{
            borderRadius: "55px",
          }}
        >
          Отправить
        </Button>
      </Grid>
    </Grid>
  );
};

export default TicketComments;
