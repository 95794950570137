import { IProfile } from "@/interfaces/profile";
import axios from "axios";
import { BX24F } from "@/libs/api.bitrix24.com";

if (process.env.REACT_APP_MODE === "prod") {
  BX24F();
}

const { BX24 } = window as any;

if (!BX24) {
  console.error("BX24 is not defined");
} else {
  BX24.init(() => {
    console.log("BX24 initialized");
  });
}

const { REACT_APP_API_URL } = process.env;

const details = navigator.userAgent;

if (!navigator?.userAgent) {
  throw new Error("User agent is not defined");
}

const regexp = /android|iphone|kindle|ipad/i;
const isMobileDevice = regexp.test(details);

console.log(isMobileDevice, details, "isMobileDevice");

if (!REACT_APP_API_URL) {
  throw new Error("REACT_APP_API_URL is not defined");
}

const DEV_TOKEN =
  "cfd986640061e68b005bfc3b000004a40000074cf686ef8056348da2d61ce5d01fe1a0";

const api = axios.create({
  withCredentials: true,
  baseURL: REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    accesstoken: BX24 ? BX24.getAuth().access_token : DEV_TOKEN,
  },
});

const generateHeaders = () => {
  return {
    headers: {
      "Content-Type": "application/json",
      accesstoken:
        process.env.REACT_APP_MODE === "dev"
          ? DEV_TOKEN
          : BX24.getAuth().access_token,
    },
  };
};

export const auth_api = {
  getToken: async () => {
    if (process.env.REACT_APP_MODE === "dev") {
      console.log("DEV MODE: NEED REFRESH TOKEN (DEV_TOKEN)", DEV_TOKEN);
      return new Promise((resolve, reject) => {
        resolve(DEV_TOKEN);
        reject(DEV_TOKEN);
      });
    } else {
      if (isMobileDevice) {
        BX24.reloadWindow();
      }
      return new Promise((resolve, reject) => {
        BX24.refreshAuth((result: any) => {
          resolve(result.access_token);
          reject(result);
        });
      });
    }
  },
  getProfile: async () => {
    return await api.get(`/profile`, generateHeaders());
  },
  getPermissions: async () => {
    return await api.get(`/permissions`, generateHeaders());
  },
};

export const tickets_api = {
  getTickets: async () => {
    return await api.get(`/tickets`, generateHeaders());
  },
  getTicketsNotConfirm: async () => {
    return await api.get(`/tickets/not-confirmed`, generateHeaders());
  },
  getTicketsCompletion: async () => {
    return await api.get(`/tickets/completion`, generateHeaders());
  },
  getTicketsRejected: async () => {
    return await api.get(`/tickets/rejected`, generateHeaders());
  },
  getTicketsByID: async (id: number) => {
    return await api.get(`/tickets/${id}`, generateHeaders());
  },
  getTicketComments: async (id: number) => {
    return await api.get(`/tickets/${id}/comments`, generateHeaders());
  },
  createNewComment: async (id: number, comment: string) => {
    return await api.post(
      `/tickets/${id}/comments`,
      { comment },
      generateHeaders()
    );
  },
  deleteTicketComment: async (id: number, commentId: number) => {
    return await api.delete(
      `/tickets/${id}/comments/${commentId}`,
      generateHeaders()
    );
  },
  updateTicketComment: async (
    id: number,
    commentId: number,
    comment: string
  ) => {
    return await api.patch(
      `/tickets/${id}/comments/${commentId}`,
      { comment },
      generateHeaders()
    );
  },
  updateTicketScore: async (id: number, body: any) => {
    return await api.patch(
      `/tickets/${id}/score`,
      { ...body },
      generateHeaders()
    );
  },
  handleCreateTicket: async (data: any) => {
    if (BX24) {
      return new Promise((resolve, reject) => {
        BX24.callMethod(
          "crm.item.add",
          {
            entityTypeId: 146,
            fields: data,
            params: { REGISTER_SONET_EVENT: "Y" },
          },
          (res: any) => {
            if (!res.data()) {
              reject([]);
            }
            resolve(res.data());
          }
        );
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve([]);
        reject([]);
      });
    }
  },
};

export const system_api = {
  getTypesForCreateTicket: async () => {
    if (BX24) {
      return new Promise((resolve, reject) => {
        BX24.callMethod(
          "crm.item.fields",
          { entityTypeId: 146 },
          (res: any) => {
            if (res.status === 200) {
              let persons = res.answer.result.fields.ufCrm29_1678112543.items;

              console.log(persons, "types from BX24");

              if (!persons || persons?.length === 0) {
                reject([]);
              }

              const result = [...persons];
              resolve(result);
            }
          }
        );
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve([
          {
            ID: "4817",
            VALUE: "Репорт бага (дев)",
          },
          {
            ID: "4818",
            VALUE: "Запрос фичи (дев)",
          },
        ]);

        reject([]);
      });
    }
  },
  getCategoriesForCreateTicket: async () => {
    if (BX24) {
      return new Promise((resolve, reject) => {
        BX24.callMethod(
          "crm.item.fields",
          { entityTypeId: 146 },
          (res: any) => {
            if (res.status === 200) {
              const result =
                res.answer.result.fields.ufCrm29_1671563694253.items;
              console.log("categoroes from bitrix", result);

              if (!result || result?.length === 0) {
                reject([]);
              }

              resolve(result);
            }
          }
        );
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve([
          {
            ID: "4817",
            VALUE: "Котировки (дев)",
          },
          {
            ID: "4818",
            VALUE: "На стороне клиента (дев)",
          },
          {
            ID: "4819",
            VALUE: "На стороне админки (дев)",
          },
          {
            ID: "4820",
            VALUE: "Ордера (дев)",
          },
          {
            ID: "4821",
            VALUE: "Тикет система (дев)",
          },
          {
            ID: "4822",
            VALUE: "Сервер (дев)",
          },
        ]);

        reject([]);
      });
    }
  },
  getImportanceForCreateTicket: async () => {
    if (BX24) {
      return new Promise((resolve, reject) => {
        BX24.callMethod(
          "crm.item.fields",
          { entityTypeId: 146 },
          (res: any) => {
            if (res.status === 200) {
              const result =
                res.answer.result.fields.ufCrm29_1671563584997.items;

              if (!result || result?.length === 0) {
                reject([]);
              }

              resolve(result);
            }
          }
        );
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve([
          {
            ID: "4817",
            VALUE: "Не критичная (Должна быть решена в порядке очереди) (дев)",
          },
          {
            ID: "4818",
            VALUE: "Критичная (Должна быть решена в ближайшие 24 часа) (дев)",
          },
          {
            ID: "4819",
            VALUE:
              "Останавливает работу (Должна быть решена незамедлительно) (дев)",
          },
        ]);

        reject([]);
      });
    }
  },
};

api.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response) {
      // ? Access Token was expired
      if (error.response.status === 401 && !originalRequest._retry) {
        console.log(originalRequest, "originalRequest");
        originalRequest._retry = true;

        await auth_api
          .getToken()
          .then((res: any) => {
            console.log(res, "res");
            if (res?.status === 201) {
              alert("Your session has expired. Please, click OKAY to login.");
              return api(originalRequest);
            }
          })
          .catch((err) => {
            if (err.response && err.response.data) {
              console.log(err.response.data, "err.response.data");
              alert("Your session has expired. Please, click OKAY to login.");
              window.location.reload();
              return Promise.reject(err.response.data);
            }
            return Promise.reject(err);
          });
      }
      if (error.response.status === 403 && error.response.data) {
        return Promise.reject(error.response.data);
      }
    }
    return Promise.reject(error);
  }
);
